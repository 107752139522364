import React from "react"
import { Link, Image } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"
import Logo from "../images/logo-db.png"
import FacebookIcon from "../images/facebook-icon.png"
import InstagramIcon from "../images/instagram-icon.png"
import WhatsappIcon from "../images/whatsapp-icon.png"
import LinkedinIcon from "../images/linkedin-icon.png"
import YouTubeIcon from "../images/youtube-icon.png"

const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  return (
    <ParallaxProvider>
      <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
        <header className="site-head">
          <div className="site-head-container">
            <a
              className="nav-burger"
              href={`#`}
              onClick={() => setToggleNav(!toggleNav)}
            >
              <div
                className="hamburger hamburger--collapse"
                aria-label="Menu"
                role="button"
                aria-controls="navigation"
              >
                <div className="hamburger-box">
                  <div className="hamburger-inner" />
                </div>
              </div>
            </a>
            <nav id="swup" className="site-head-left">
              <ul className="nav" role="menu">
                {/* <li className="nav-home nav-current" role="menuitem">
                <Link to={`/`}>Home</Link>
              </li> */}
                <li className="nav-about" role="menuitem">
                  <Link to={`/prazer-denise`}>Prazer, Denise!</Link>
                </li>
                <li className="nav-elements" role="menuitem">
                  <Link to={`/para-voce`}>Para Você</Link>
                </li>
                <li className="nav-tags" role="menuitem">
                  <Link to={`/para-empresa`}>Para Empresa</Link>
                </li>
                <li className="nav-tags" role="menuitem">
                  <Link to={`/cursos`}>Cursos</Link>
                </li>
                <li className="nav-tags" role="menuitem">
                  <Link to={`/blog`}>Blog</Link>
                </li>
              </ul>
            </nav>
            <div className="site-head-center">
              <Link className="site-head-logo" to={`/`}>
                <img src={Logo} alt="Denise Barbosa" />
              </Link>
            </div>
            <div className="site-head-right">
              <div className="social-links">
                <Link to={`/contato`}>Contato</Link>

                <a
                  href="https://www.linkedin.com/in/denisebarbosa0209/"
                  title="LinkedIn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
                <a
                  href="https://www.youtube.com/channel/UC5IOhNNlIz4bR54l8Ttj70A?view_as=subscriber"
                  title="YouTube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YouTube
                </a>
                <a
                  href="https://www.facebook.com/denisebarbosa.com.br/"
                  title="Facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
                <a
                  href="https://www.instagram.com/db_denisebarbosa/"
                  title="Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </div>
            </div>
          </div>
        </header>
        <main id="site-main" className="site-main">
          <div id="swup2" className="transition-fade">
            {children}
          </div>
        </main>
      </div>
      <footer className="site-foot">
        <div className="row">
          <div className="col-4">
            <p>
              Atendimento Online e Presencial
              <br />
              <br />
              <a
                href="/contato"
                title="Contato"
                className="calltoaction-button-rodape"
                style={{ width: `60%`, alignSelf: `center` }}
              >
                Saber Mais
              </a>
            </p>
          </div>
          <div className="col-4">
            <p style={{ fontSize: `20px`, color: `white` }}>
              <b>DENISE BARBOSA</b>
            </p>
            <p>
              Especialista DHO e Performance Profissional | Mentoria e Design de
              Carreira | Consultoria Projetos Estratégicos RH
            </p>
          </div>
          <div className="col-4">
            <br />
            <a
              href="https://www.linkedin.com/in/denisebarbosa0209/"
              title="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: `none` }}
            >
              <img src={LinkedinIcon} alt="LinkedIn" />
            </a>
            &nbsp;&nbsp;&nbsp;
            <a
              href="https://www.facebook.com/denisebarbosa.com.br/"
              title="Facebook"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: `none` }}
            >
              <img src={FacebookIcon} alt="Facebook" />
            </a>
            &nbsp;&nbsp;&nbsp;
            <a
              href="https://www.youtube.com/channel/UC5IOhNNlIz4bR54l8Ttj70A?view_as=subscriber"
              title="Youtube"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: `none` }}
            >
              <img
                src={YouTubeIcon}
                alt="Youtube"
                style={{ opacity: 0.83, padding: `3px` }}
              />
            </a>
            &nbsp;&nbsp;&nbsp;
            <a
              href="https://www.instagram.com/db_denisebarbosa/"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: `none` }}
            >
              <img src={InstagramIcon} alt="Instagram" />
            </a>
            &nbsp;&nbsp;&nbsp;
            <a
              href="https://api.whatsapp.com/send?phone=5541991732728&text=Oi%2C%20quero%20saber%20mais"
              title="Whatsapp"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: `none` }}
            >
              <img src={WhatsappIcon} alt="WhatsApp" />
            </a>
          </div>
        </div>
        <br />
        <br />
        <span style={{ fontSize: `11px` }}>
          &copy; {new Date().getFullYear()} {title} - feito por{" "}
          <a
            href="https://www.codebox.com.br"
            title="CodeBox"
            target="_blank"
            rel="noopener noreferrer"
            alt="CodeBox"
          >
            CodeBox
          </a>
        </span>
      </footer>
    </ParallaxProvider>
  )
}

export default Layout
